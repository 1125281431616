import React from 'react';
import { graphql } from 'gatsby';

import SEO from 'components/SEO';
import Content from 'components/DeleteAccountComponents/Content';

export const query = graphql`
  query AboutUsPageQuery {
    allPrismicAboutUsRootPage {
      nodes {
        dataRaw
      }
    }
  }
`;

const DeletingAccount = ({ data }) => {
  const pageData = data.allPrismicAboutUsRootPage.nodes[0].dataRaw;
  const title = 'Deleting Account';

  return (
    <>
      <SEO
        pageTitle={title}
        ogTitle={title}
        twitterTitle={title}
        pageImage={pageData?.page_image?.url}
        pageImageAlt={pageData?.page_image?.alt}
        twitterImage={pageData?.twitter_image?.url}
        twitterImageAlt={pageData?.twitter_image?.alt}
        ogImage={pageData?.og_image?.url}
        ogImageAlt={pageData?.og_image?.url?.alt}
      />
      <Content />
    </>
  );
};

export default DeletingAccount;
