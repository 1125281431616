import React from 'react';
import styled from '@emotion/styled';

import sleep from 'assets/others/aboutUs/sleep.jpg';
import Checkmark from 'assets/svgs/about_us_checkmark.svg';
import { BREAKPOINTS, MOBILE_SIDE_PADDING, WEB_SIDE_PADDING } from 'utils/constants';

const CATEGORIES_DATA = [
  {
    title: '',
    image: sleep,
    content: [
      'Drop a line to us from an email you have signed up with at',
      'We will take care of everything else',
    ],
  },
];

interface ICategoryImage {
  image: string;
}

interface ICategoryContainer {
  index: number;
  isLast: boolean;
}

interface IsLast {
  isLast: boolean;
}

const Container = styled.div({
  padding: `116px ${WEB_SIDE_PADDING} 20px ${WEB_SIDE_PADDING}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  [BREAKPOINTS[800]]: {
    padding: `116px ${MOBILE_SIDE_PADDING} 20px ${MOBILE_SIDE_PADDING}`,
  },
});

const Title = styled.div((props) => ({
  color: props.theme.colors.white,
  fontSize: 40,
  letterSpacing: 2,
  fontWeight: 600,
  textAlign: 'center',
}));

const TitleLight = styled.span({
  marginTop: 20,
  fontWeight: 100,
});

const Categories = styled.div({
  width: '100%',
  maxWidth: 970,
  marginTop: 60,
});

const CategoryContainer = styled.div<ICategoryContainer>((props) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '52px 0',
  borderBottom: props.isLast ? '' : '1px solid rgba(255, 255, 255, 0.08)',
  [BREAKPOINTS[955]]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const CategoryImage = styled.div<ICategoryImage>((props) => ({
  minWidth: 367,
  minHeight: 338,
  width: 367,
  height: 338,
  backgroundImage: `url(${props.image})`,
  backgroundPosition: 'center center',
  backgroundSize: '100% auto',
  backgroundRepeat: 'no-repeat',
  borderRadius: 4,
  [BREAKPOINTS[955]]: {
    minWidth: 0,
    minHeight: 0,
  },
}));

const CategoryTitle = styled.div((props) => ({
  color: props.theme.colors.white,
  fontSize: 24,
  letterSpacing: 2,
  marginBottom: 24,
  fontWeight: 500,
  [BREAKPOINTS[955]]: {
    marginTop: 40,
  },
}));

const CategoryPointContainer = styled.div({
  display: 'flex',
  paddingTop: 24,
  marginBottom: 8,
});

const CheckmarkContainer = styled.div({
  marginTop: 4,
  marginRight: 8,
});

const CategoryTextContainer = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
}));

const CategoryPointText = styled.li((props) => ({
  color: props.theme.colors.white,
  opacity: 0.8,
  fontSize: 14,
  lineHeight: '28px',
  letterSpacing: 1,
}));

const Link = styled.a<IsLast>((props) => ({
  color: props.theme.colors.white,
  textDecoration: 'underline',
  display: props.isLast ? 'none' : 'inline',
  marginLeft: 4,
}));

const Content: React.FC = () => (
  <Container>
    <Title>
      If it's time to say goodbye,
      <TitleLight> here's how to go about it</TitleLight>
    </Title>
    <Categories>
      {CATEGORIES_DATA.map((category, index) => (
        <CategoryContainer
          isLast={CATEGORIES_DATA.length - 1 === index}
          index={index}
          key={category.title}
        >
          <CategoryImage image={category.image} />
          <CategoryTextContainer>
            <CategoryTitle>{category.title}</CategoryTitle>
            <ul>
              {category.content.map((text, k) => (
                <CategoryPointContainer key={text}>
                  <CheckmarkContainer>
                    <Checkmark />
                  </CheckmarkContainer>
                  <CategoryPointText>
                    {text}

                    <Link isLast={category.content.length - 1 === k}>accounts@yoursapp.com</Link>
                  </CategoryPointText>
                </CategoryPointContainer>
              ))}
            </ul>
          </CategoryTextContainer>
        </CategoryContainer>
      ))}
    </Categories>
  </Container>
);

export default Content;
